<template>
  <vx-card style="padding-left: 10px">
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="email">Reported BY</label>
           <p id="email">{{reportDetail.userId.email}}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="message">Message</label>
          <p
            id="message"
            style="white-space: pre-wrap;"
          >{{reportDetail.message?reportDetail.message:'N/A'}}</p>
        </div>
      </vs-col>
      <vs-divider position="left" color="danger">Vehicle Basic Information</vs-divider>
      <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="cardetails">Car Details</label> 
          <p id="cardetails" v-if="reportDetail.carId!=null">
            <vs-button
              style="padding-left:0px; padding-right:0px;"
              color="rgb(16, 235, 56)"
              type="line"
              @click="viewCarDetails(reportDetail.carId._id)"
              v-if="!reportDetail.carId.deleted"
            >Show Car Detail</vs-button>
            <vs-button
              color="rgb(225, 30, 38)"
              type="flat"
              v-else
              disabled
              style="padding-left:0px; padding-right:0px;"
            >Car has been Deleted</vs-button>
          </p>
          <p id="cardetails" v-else>
            <vs-button
              color="rgb(225, 30, 38)"
              type="flat"
              disabled
              style="padding-left:0px; padding-right:0px;"
            >Car has been Deleted</vs-button>
          </p>
        </div>
      </vs-col>-->
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="email">Owned BY</label>
          <p id="email" v-if="reportDetail.carId.sellerType ==='private'">{{reportDetail.carId.userId.email}}</p>
          <p id="email" v-if="reportDetail.carId.sellerType ==='dealership'">{{reportDetail.carId.dealerId.contactInformation.email}}</p>
        </div>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="reportDetail.carId.licencePlate"
      >
        <div class="w-full m-5">
          <label for="licencePlate">Licence Plate</label>
          <p
            id="licencePlate"
          >{{reportDetail.carId.licencePlate?reportDetail.carId.licencePlate:"N/A"}}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="make">Maker</label>
          <p id="make">{{reportDetail.carId.make?reportDetail.carId.make:"N/A"}}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="model">Model</label>
          <p id="model">{{reportDetail.carId.model?reportDetail.carId.model:"N/A"}}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="year">Year</label>
          <p id="year">{{reportDetail.carId.year?reportDetail.carId.year:"N/A"}}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="badge">Badge</label>
          <p id="badge">{{reportDetail.carId.badge?reportDetail.carId.badge:"N/A"}}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="transmission">Transmission</label>
          <p
            id="transmission"
          >{{reportDetail.carId.transmission?reportDetail.carId.transmission:"N/A"}}</p>
        </div>
      </vs-col>
      <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="cylinderConfiguration">Cylinder Configuration</label>
          <p
            id="cylinderConfiguration"
          >{{reportDetail.carId.cylinderConfiguration ?reportDetail.carId.cylinderConfiguration:"N/A"}}</p>
        </div>
      </vs-col>-->

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="registrationState">Registration State</label>
          <p
            id="registrationState"
          >{{reportDetail.carId.registrationState ?reportDetail.carId.registrationState:"N/A"}}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="bodyType">Body Type</label>
          <p id="bodyType">{{reportDetail.carId.bodyType ?reportDetail.carId.bodyType:"N/A"}}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="description">Description</label>
          <p
            id="description"
            style="white-space: pre-wrap;"
          >{{reportDetail.carId.description ?reportDetail.carId.description:"N/A"}}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="exteriorColor">Exterior Color</label>
          <p
            id="exteriorColor"
          >{{reportDetail.carId.exteriorColor ?reportDetail.carId.exteriorColor:"N/A"}}</p>
        </div>
      </vs-col>
    </vs-row>
    <vs-row vs-justify="flex-end">
      <vs-button
        color="danger"
        style="margin-left:5px;"
        @click="$router.replace('/admin/report')"
      >Back</vs-button>
    </vs-row>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "Report",
  data() {
    return {
      reportDetail: {
        userId: {},
        carId: {
          userId: {},
        },
      },
    };
  },
  methods: {
    ...mapActions("report", ["fetchReport"]),
    getReportDetail(id) {
      let self = this;
      this.fetchReport(id).then((res) => {
        this.reportDetail = res.data.data;
      });
    },
    viewCarDetails(id) {
      this.$router.push({ name: "VehicleDetails", params: { id: id } });
    },
  },
  created() {
    let reportId = this.$route.params.id;
    this.getReportDetail(reportId);
  },
};
</script>
<style>
.ql-editor {
  min-height: 200px;
}
</style>